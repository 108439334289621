/* globals AbstractSourceBasedModel, Comparison, Enums, Grid, Validate */
(function () {
  'use strict';
  const PROPERTIES = Object.freeze([
    'chartInputs',
    'dates',
    'downloadGrainFilter',
    'editType',
    'enabled',
    'filters',
    'flow',
    'granularity',
    'groupBy',
    'historicalDividerIndex',
    'metrics',
    'plan',
    'records',
    'scope',
    'showComparisonAs',
    'showDataAs',
    'showViewAs',
    'timeUnit',
    'title',
    'totals',
    'type',
    'viewGrainFilter'
  ]);
  const PERCENTILES_OPTIONS = Object.freeze({ property: 'values', set: 'values' });

  class DataPackage extends AbstractSourceBasedModel {
    constructor (source, promiseService) {
      if (_.isNil(promiseService)) {
        throw new Error('DataPackage: no promise service provided.');
      }
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.promiseService = promiseService;
    }

    /* Determines if a DataPackage has both DataMode and ComparisonMode set to Unit
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if a DataPackage has both DataMode and ComparisonMode set to Unit
     */
    static areModesUnit (dataPackage) {
      return _.get(dataPackage, 'showComparisonAs') === Enums.DataPackage.ComparisonMode.UNIT &&
        _.get(dataPackage, 'showDataAs') === Enums.DataPackage.DataMode.UNIT;
    }

    /**
     * Determines if a cell is editable
     * @param {DataCell} col the DataCell to validate
     * @returns {boolean} indicating if the cell can be edited
     */
    static colIsEditable (col) {
      if (_.isNil(col)) {
        return false;
      }
      return _.isNil(col.dataType) || col.dataType === Enums.DataPackage.RecordType.EDITS;
    }

    /* Determines if a DataPackage contains only finite edit values
     *
     * @param dataPackage the DataPackage to validate.
     * @return Boolean indicating if edits are all finite
     */
    static containsValidEdits (dataPackage) {
      const editRecords = DataPackage.filter(dataPackage, DataPackage.rowContainsEdits);
      if (_.isEmpty(editRecords)) {
        return false;
      }
      return _.every(editRecords, DataPackage.rowContainsValidEdits);
    }

    /* Determines if a DataPackage contains an edit value
     *
     * @param dataPackage the DataPackage to validate.
     * @return Boolean indicating if an edit is present
     */
    static containsEdits (dataPackage) {
      return !_.isEmpty(DataPackage.filter(dataPackage, DataPackage.rowContainsEdits));
    }

    /* Filters the records of DataPackage to those that return truthy by the predicate
     *
     * @param dataPackage the DataPackage.
     * @param predicate the matchee, property, or function predicate to evaluate truthiness
     * @return Array of records that match predicate, or an empty array if no match
     */
    static filter (dataPackage, predicate) {
      if (DataPackage.isEmpty(dataPackage)) {
        return [];
      }
      return _.filter(dataPackage.records, predicate);
    }

    /* Determines if a DataPackage has a records array property
     *
     * @param dataPackage the DataPackage to validate.
     * @return Boolean indicating if records property is an array in the dataPackage
     */
    static hasRecordsArray (dataPackage) {
      return _.has(dataPackage, 'records') && Array.isArray(dataPackage.records);
    }

    /* Determines if a DataPackage is of type COMPACT_PLAN
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage is of type COMPACT_PLAN
     */
    static isCompactPlan (dataPackage) {
      return _.get(dataPackage, 'type') === Enums.DataPackage.PackageType.COMPACT_PLAN;
    }

    /* Determines if a DataPackage is of type CREATE
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage is of type CREATE
     */
    static isCreate (dataPackage) {
      return _.get(dataPackage, 'type') === Enums.DataPackage.PackageType.CREATE;
    }

    /* Determines if a DataPackage is of type EDIT
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage is of type EDIT
     */
    static isEdit (dataPackage) {
      return _.get(dataPackage, 'type') === Enums.DataPackage.PackageType.EDIT;
    }

    /* Determines if a DataPackage has a Delta editType
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage has a Delta editType
     */
    static isDeltaEdit (dataPackage) {
      return _.get(dataPackage, 'editType') === Enums.DataPackage.EditType.DELTA;
    }

    /* Determines if a DataPackage has a Ratio editType
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage has a Ratio editType
     */
    static isRatioEdit (dataPackage) {
      return _.get(dataPackage, 'editType') === Enums.DataPackage.EditType.RATIO;
    }

    /* Determines if a DataPackage has a Unit editType
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage has a Unit editType
     */
    static isUnitEdit (dataPackage) {
      return _.get(dataPackage, 'editType') === Enums.DataPackage.EditType.UNIT;
    }

    /* Determines if a DataPackage has no records
     *
     * @param dataPackage the DataPackage to validate.
     * @return Boolean indicating if there are no records in the dataPackage
     */
    static isEmpty (dataPackage) {
      return !DataPackage.hasRecordsArray(dataPackage) || _.isEmpty(dataPackage.records);
    }

    /* Determines if a DataPackage's values can be changed
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if records can be changed
     */
    static isMutable (dataPackage) {
      if (_.isNil(dataPackage)) {
        return false;
      }
      return DataPackage.isEdit(dataPackage) || DataPackage.isCreate(dataPackage);
    }

    /* Determines if a DataPackage has a Week timeUnit
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage has a Week timeUnit
     */
    static isTimeUnitWeek (dataPackage) {
      return _.get(dataPackage, 'timeUnit') === Enums.TimeUnit.WEEK;
    }

    /* Determines if a DataPackage row contains an edit value
     *
     * @param row the DataPackage row to validate.
     * @return Boolean indicating if an edit is present
     */
    static rowContainsEdits (row) {
      return DataPackage.rowIsEditable(row) && _.some(row.values, _.isNumber);
    }

    /* Determines if a DataPackage row contains only finite edit values
     *
     * @param row the DataPackage row to validate.
     * @return Boolean indicating if edits are all finite
     */
    static rowContainsValidEdits (row) {
      return DataPackage.rowContainsEdits(row) && _.isEmpty(Validate.checkNonNumericals(row.values));
    }

    /* Determines if a DataPackage row is for edit difference
     *
     * @param row the DataPackage row to validate.
     * @return Boolean indicating if the row is for edit difference
     */
    static rowIsEditDifference (row) {
      return _.get(row, 'dataType') === Enums.DataPackage.RecordType.DIFFERENCE;
    }

    /* Determines if a DataPackage row is editable
     *
     * @param row the DataPackage row to validate.
     * @return Boolean indicating if the row can be edited
     */
    static rowIsEditable (row) {
      if (_.isNil(row)) {
        return false;
      }
      return _.isNil(row.dataType) || row.dataType === Enums.DataPackage.RecordType.EDITS;
    }

    /* Determines if a DataPackage row is for edit preview
     *
     * @param row the DataPackage row to validate.
     * @return Boolean indicating if the row is for edit preview
     */
    static rowIsEditPreview (row) {
      return _.get(row, 'dataType') === Enums.DataPackage.RecordType.PREVIEW;
    }

    /* Determines if a DataPackage is of type COMPACT_PLAN
     *
     * @param dataPackage the DataPackage to check.
     * @return Boolean indicating if dataPackage is of type COMPACT_PLAN
     */
    isCompactPlan () {
      return DataPackage.isCompactPlan(this);
    }

    /* Calculates the percentiles of each metric record based off the metric's total
     * This method mutates the records property on the DataPackage
     *
     * Assumptions:
     *   records of the same dataType and metric all have the same metric reference
     */
    percentilesByMetric () {
      // Aggregate records and totals by dataType { primary: [m1, m2], comparison: [m1, m2], actuals: [m1, m2] }
      const recordsGroupedByDataType = _.groupBy(this.records, (record) => record.dataType);
      const totalsGroupedByDataType = _.groupBy(this.totals, (record) => record.dataType);

      // For every dataType group its records by metric reference
      _.forEach(recordsGroupedByDataType, (recordsByDataType, dataType) => {
        const recordsByMetric = new Map();
        recordsByDataType.forEach((row) => recordsByMetric.set(row.metric, [row]));

        // Find the corresponding totals row based off dataType and compute the percentiles
        recordsByMetric.forEach((records) => Grid.percentiles(records, _.head(records).values.length, _.head(totalsGroupedByDataType[dataType]).values, PERCENTILES_OPTIONS));
      });
      // Update the records metric to display the values as percentages
      this.records = this.records.map((record) =>
        _.set(record, 'metric', _.defaults({ dataType: Enums.DataPackage.MetricDataType.PERCENT, decimalPlaces: 1 }, record.metric)));
    }

    /* Calculates the percentiles of each metric record based off the metric's total
     * This method mutates the records property on the DataPackage
     *
     * Assumptions:
     *   records of the same dataType and metric all have the same metric reference
     */
    percentilesBySubtotal () {
      // Aggregate records and totals by dataType { primary: [m1, m2], comparison: [m1, m2], actuals: [m1, m2] }
      const recordsGroupedByDataType = _.groupBy(this.records, (record) => record.dataType);
      const totalsGroupedByDataType = _.groupBy(this.totals, (record) => record.dataType);

      _.forEach(recordsGroupedByDataType, (recordsByDataType, dataType) => {
        // Map all rows by their granularity and give each value a property 'subtotalKey' that correlates to the
        // key of that row's subtotal row.
        // E.g. a row with the following granularity: { node: 'ABC1', productLine: 'Amazon Books'}
        // becomes
        // 'nodeABC1productLineAmazon Books': {
        //   cells: ...,
        //   dataType: ...,
        //   granularity: { node: 'ABC1', productLine: 'Amazon Books'},
        //   metric: ...,
        //   subtotalKey: 'nodeABC1productLineSubtotal'
        // }
        // in recordsByMetric.
        const recordsByMetric = {};
        recordsByDataType.forEach((row) => {
          _.set(row, 'subtotalKey', this._getSubtotalGranularityKey(row.granularity));
          _.set(recordsByMetric, this._getGranularityKey(row.granularity), row);
        });

        // Iterate through the map to calculate each row's percentile by finding the quotient of it and its respective subtotal row.
        _.forEach(recordsByMetric, (records) => {
          let divisor = _.get(recordsByMetric[records.subtotalKey], 'values');
          if (_.isNil(recordsByMetric[records.subtotalKey])) {
            // When the row's subtotal is not present in the map, this indicates the row's subtotal is the grand total, thus assign its divisor accordingly.
            // E.g. A row with subtotal key of 'nodeSubtotalproductLineSubtotal' would mean a subtotal granularity of { node: 'Subtotal', productLine: 'Subtotal'}
            // This refers to the grand total as it is the ultimate subtotal row in a sense.
            divisor = _.head(totalsGroupedByDataType[dataType]).values;
          }
          Grid.percentiles([records], records.values.length, divisor, PERCENTILES_OPTIONS);
        });
      });
      // Update the records metric to display the values as percentages
      this.records = this.records.map((record) =>
        _.set(record, 'metric', _.defaults({ dataType: Enums.DataPackage.MetricDataType.PERCENT, decimalPlaces: 1 }, record.metric)));
    }

    // Helper method produces the key of the subtotal granularity for the provided granularity.
    // First the subtotal granularity of the provided granularity is found, then its key is returned.
    // E.g. { node: 'ABC1', productLine: 'Amazon Books'} -> { node: 'ABC1', productLine: 'Subtotal'} -> 'nodeABC1productLineSubtotal'
    // E.g. { node: 'ABC1', productLine: 'Subtotal'} -> { node: 'Subtotal', productLine: 'Subtotal'} -> 'nodeSubtotalproductLineSubtotal'
    // E.g. { node: 'ABC1', productLine: 'Amazon Books', sortType: 'sortable'} -> { node: 'ABC1', productLine: 'Amazon Books', sortType: 'Subtotal'} -> 'nodeABC1productLineAmazon BookssortTypeSubtotal'
    _getSubtotalGranularityKey (granularity) {
      const grainValues = this.granularity.grains.values();
      const keyObject = grainValues.reduce((rowKeyObject, grain, index) => {
        // If the row is a subtotal row, assigning the previous grain's value to 'Subtotal' will
        // produce the row's subtotal granularity.
        if (granularity[grain.id] === Enums.AggregateType.SUBTOTAL) {
          _.set(rowKeyObject, grainValues[index].id, Enums.AggregateType.SUBTOTAL);
          return _.set(rowKeyObject, grainValues[index - 1].id, Enums.AggregateType.SUBTOTAL);
        }
        // If the grain is the final grain, then it should be set to 'Subtotal' to produce the row's subtotal row.
        if (index === grainValues.length - 1) {
          return _.set(rowKeyObject, grainValues[index].id, Enums.AggregateType.SUBTOTAL);
        }
        // If neither of those cases, then copy the row's granularity.
        return _.set(rowKeyObject, grain.id, granularity[grain.id]);
      }, {});
      // Finally, convert the object into a string to be used as a key.
      return this._getGranularityKey(keyObject);
    }

    // Helper method produces to key of the provided granularity.
    // E.g. { node: 'ABC1', productLine: 'Amazon Books'} -> 'nodeABC1productLineAmazon Books'
    // E.g. { node: 'ABC1', productLine: 'Subtotal'} -> 'nodeABC1productLineSubtotal'
    // E.g. { node: 'ABC1', productLine: 'Amazon Books', sortType: 'sortable'} -> 'nodeABC1productLineAmazon BookssortTypesortable'
    _getGranularityKey (granularity) {
      const grainValues = this.granularity.grains.values();
      // Convert the object into a hash key by concatenating each grain id and its value into a single string.
      return grainValues.reduce((rowKeyString, grain) => rowKeyString.concat(`${grain.id}${granularity[grain.id]}`), '');
    }

    /* Calculates the amount the comparison dataset missed the reference dataset by as a percentage or delta.
     *
     * If the DataPackage contains actuals, the actuals dataset is used as the reference dataset.
     * If the DataPackage does not contains actuals, the primary forecast is used as the reference dataset.
     *
     * This method mutates the records and totals properties on the DataPackage
     */
    calculateMiss () {
      const calculateDifference = (primaryValues, secondaryValues) => primaryValues.map((primaryValue, index) => {
        const secondaryValue = secondaryValues[index];
        if (_.isFinite(primaryValue) && _.isFinite(secondaryValue)) {
          if (this.showComparisonAs === Enums.DataPackage.ComparisonMode.DELTA) {
            // Originally: secondaryValue - primaryValue; Changed as per SOP-6411
            return primaryValue - secondaryValue;
          } else if (this.showComparisonAs === Enums.DataPackage.ComparisonMode.PERCENT && secondaryValue !== 0) {
            // Originally: (secondaryValue - primaryValue) / primaryValue; Changed as per SOP-6411
            return (primaryValue - secondaryValue) / secondaryValue;
          }
        }
        return null;
      });

      const modifyRows = (dataSet) => {
        const referenceDataType = this.isCompactPlan() || !this.enabled.actuals ? Enums.DataType.PRIMARY : Enums.DataType.ACTUAL;
        const newRecords = [];

        let referenceRow;

        _.forEach(dataSet, (record) => {
          if (record.dataType === referenceDataType) {
            referenceRow = record;
            newRecords.push(referenceRow);
            return;
          }
          // If a reference row is not found by the time we find a comparison row, ignore the comparison row
          if (_.isNil(referenceRow)) {
            return;
          }
          if (Comparison.areGranularitiesEqual(referenceRow.granularity, record.granularity, { exclude: 'metric' })) {
            record.values = calculateDifference(referenceRow.values, record.values);
            // Update the row's metric if viewing as a Percentage so plan-grid displays the value formatted correctly
            record.metric = this.showComparisonAs === Enums.DataPackage.ComparisonMode.PERCENT ?
              _.defaults({ dataType: Enums.DataPackage.MetricDataType.PERCENT, decimalPlaces: 1 }, record.metric) :
              record.metric;
            newRecords.push(record);
          }
        });
        return newRecords;
      };
      this.records = modifyRows(this.records);
      this.totals = modifyRows(this.totals);
    }

    /* Returns a promise that resolves when the DataPackage is able to be used
     *
     * @return Promise indicating when the DataPackage is resolved
     */
    ready () {
      return this.promiseService.all([this.records, this.totals]).then((data) => {
        this.records = data[0];
        this.totals = data[1];

        if (!_.isNil(this.showDataAs) && this.showDataAs !== Enums.DataPackage.DataMode.UNIT) {
          // If showDataAs is not UNIT, transform the DataPackage into percentile values corresponding to a subtotal or standard view.
          // In the case where a user selects the flow "Inventory Turns" with subtotals enabled and they have selected the "Percent" View Mode, it should use the standard percentiles logic.
          // This is for a special case from the legacy system and it is currently the only exception of its kind: https://sim.amazon.com/issues/SOP-7803.
          if (this.enabled.subtotals && this.title !== 'Inventory Turns') {
            this.percentilesBySubtotal();
          } else {
            this.percentilesByMetric();
          }
        } else if (!_.isNil(this.showComparisonAs) && this.showComparisonAs !== Enums.DataPackage.ComparisonMode.UNIT) {
          // If showDataAs is UNIT and showComparisonAs is not UNIT, transform the DataPackage into miss values
          this.calculateMiss();
        }

        return this;
      });
    }
  }

  window.DataPackage = Object.freeze(DataPackage);
})();
