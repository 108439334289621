/* globals Configuration */
(function () {
  'use strict';
  const WILD = '*';

  class Rule {
    constructor (environment = WILD, scope = WILD) {
      this.environment = environment;
      this.scope = scope;
    }

    // PURE VIRTUAL - Should be implemented by sub-class.
    isApplicable () {
      throw new Error('Rule: isApplicable() must be overridden in a sub-class');
    }
  }

  class ActivationRule extends Rule {
    constructor (environment = WILD, scope = WILD) {
      super(environment, scope);
      this.isActivationRule = true;
    }

    static create (environment, scope) {
      return Object.freeze(new ActivationRule(environment, scope));
    }

    isApplicable (environment, scope) {
      return (this.environment === WILD || environment.is(this.environment))
        && (this.scope === WILD || scope.is(this.scope));
    }
  }

  class DeactivationRule extends Rule {
    constructor (environment = WILD, scope = WILD) {
      super(environment, scope);
      this.isActivationRule = false;
    }

    static create (environment, scope) {
      return Object.freeze(new DeactivationRule(environment, scope));
    }

    isApplicable (environment, scope) {
      return (this.environment === WILD || !environment.is(this.environment))
        && (this.scope === WILD || !scope.is(this.scope));
    }
  }
  Object.freeze(Rule);
  Object.freeze(ActivationRule);
  Object.freeze(DeactivationRule);

  // For any key, all rules must be instances of either ActivationRule or DeactivationRule, not a mix of both.
  const FEATURE_SUPPORT = Object.freeze({
    AllocationTreeEditor_BinTypeFcWeeklyPlan: {
      rules: [
        ActivationRule.create(WILD, 'BR'),
        ActivationRule.create(WILD, 'CA'),
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'MX'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'TR'),
        ActivationRule.create(WILD, 'US')
      ]
    },
    DailyPlanViewer_GasTankFcBtIgDailyActuals_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'CAPACITY_AE'),
        ActivationRule.create(WILD, 'CAPACITY_AU'),
        ActivationRule.create(WILD, 'CAPACITY_BR'),
        ActivationRule.create(WILD, 'CAPACITY_CA'),
        ActivationRule.create(WILD, 'CAPACITY_DE'),
        ActivationRule.create(WILD, 'CAPACITY_EG'),
        ActivationRule.create(WILD, 'CAPACITY_ES'),
        ActivationRule.create(WILD, 'CAPACITY_EU'),
        ActivationRule.create(WILD, 'CAPACITY_FR'),
        ActivationRule.create(WILD, 'CAPACITY_GB'),
        ActivationRule.create(WILD, 'CAPACITY_IN'),
        ActivationRule.create(WILD, 'CAPACITY_IT'),
        ActivationRule.create(WILD, 'CAPACITY_JP'),
        ActivationRule.create(WILD, 'CAPACITY_MX'),
        ActivationRule.create(WILD, 'CAPACITY_SA'),
        ActivationRule.create(WILD, 'CAPACITY_SE'),
        ActivationRule.create(WILD, 'CAPACITY_SG'),
        ActivationRule.create(WILD, 'CAPACITY_TR'),
        ActivationRule.create(WILD, 'CAPACITY_US')
      ]
    },
    GroupBy_nodeRegion: {
      rules: [
        ActivationRule.create(WILD, 'US'),
        ActivationRule.create(WILD, 'CAPACITY_US'), // Configuration.environment.designators.BETA.should.equal('beta') Configuration.environment.designators.GAMMA.
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CAPACITY_CA')
      ]
    },
    GroupBy_secondaryNodeRegion: {
      rules: [
        ActivationRule.create(WILD, 'US'),
        ActivationRule.create(WILD, 'CAPACITY_US'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CAPACITY_CA')
      ]
    },
    ItemSelector_RegionFilters: {
      rules: [
        ActivationRule.create(WILD, 'US'),
        ActivationRule.create(WILD, 'CAPACITY_US'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CAPACITY_CA')
      ]
    },
    ManualBacklog_AllowVirtualPLs: {
      rules: [
        ActivationRule.create(WILD, 'CA')
      ]
    },
    NetworkViewer_RegionGroups: {
      rules: [
        ActivationRule.create(WILD, 'US'),
        ActivationRule.create(WILD, 'CAPACITY_US'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.GAMMA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CA'),
        ActivationRule.create(Configuration.environment.designators.BETA, 'CAPACITY_CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CA'),
        ActivationRule.create(Configuration.environment.designators.LOCAL, 'CAPACITY_CA')
      ]
    },
    PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_BEG_Arc_Inbound: {
      rules: [
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'TR')
      ]
    },
    PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_BEG_Arc_Outbound: {
      rules: [
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'TR')
      ]
    },
    PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_FC: {
      rules: [
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'TR')
      ]
    },
    TriggerCapacity: {
      rules: [
        ActivationRule.create(WILD, 'CA'),
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IN'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'JP'),
        ActivationRule.create(WILD, 'MX'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'TR'),
        ActivationRule.create(WILD, 'US')
      ]
    },
    TriggerCapacityOffline: {
      rules: [
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'US')
      ]
    },
    TriggerFCGasTank: {
      rules: [
        ActivationRule.create(WILD, 'AE'),
        ActivationRule.create(WILD, 'AU'),
        ActivationRule.create(WILD, 'BR'),
        ActivationRule.create(WILD, 'CA'),
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'EG'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'JP'),
        ActivationRule.create(WILD, 'MX'),
        ActivationRule.create(WILD, 'SA'),
        ActivationRule.create(WILD, 'SG'),
        ActivationRule.create(WILD, 'US')
      ]
    },
    WeeklyPlanViewer_BinType_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'CA'),
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'MX'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'US')
      ]
    },
    WeeklyPlanViewer_GasTankPloNigBtg_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'CAPACITY_AE'),
        ActivationRule.create(WILD, 'CAPACITY_AU'),
        ActivationRule.create(WILD, 'CAPACITY_BR'),
        ActivationRule.create(WILD, 'CAPACITY_CA'),
        ActivationRule.create(WILD, 'CAPACITY_DE'),
        ActivationRule.create(WILD, 'CAPACITY_EG'),
        ActivationRule.create(WILD, 'CAPACITY_ES'),
        ActivationRule.create(WILD, 'CAPACITY_EU'),
        ActivationRule.create(WILD, 'CAPACITY_FR'),
        ActivationRule.create(WILD, 'CAPACITY_GB'),
        ActivationRule.create(WILD, 'CAPACITY_IN'),
        ActivationRule.create(WILD, 'CAPACITY_IT'),
        ActivationRule.create(WILD, 'CAPACITY_JP'),
        ActivationRule.create(WILD, 'CAPACITY_MX'),
        ActivationRule.create(WILD, 'CAPACITY_SA'),
        ActivationRule.create(WILD, 'CAPACITY_SE'),
        ActivationRule.create(WILD, 'CAPACITY_SG'),
        ActivationRule.create(WILD, 'CAPACITY_TR'),
        ActivationRule.create(WILD, 'CAPACITY_US')
      ]
    },
    WeeklyPlanViewer_PlStFc_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'AE'),
        ActivationRule.create(WILD, 'AU'),
        ActivationRule.create(WILD, 'BR'),
        ActivationRule.create(WILD, 'CA'),
        ActivationRule.create(WILD, 'DE'),
        ActivationRule.create(WILD, 'EG'),
        ActivationRule.create(WILD, 'ES'),
        ActivationRule.create(WILD, 'EU'),
        ActivationRule.create(WILD, 'FR'),
        ActivationRule.create(WILD, 'GB'),
        ActivationRule.create(WILD, 'IN'),
        ActivationRule.create(WILD, 'IT'),
        ActivationRule.create(WILD, 'JP'),
        ActivationRule.create(WILD, 'MX'),
        ActivationRule.create(WILD, 'SA'),
        ActivationRule.create(WILD, 'SE'),
        ActivationRule.create(WILD, 'SG'),
        ActivationRule.create(WILD, 'TR'),
        ActivationRule.create(WILD, 'US'),
        ActivationRule.create(WILD, 'ZA')
      ]
    },
    WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWeeklyInputPlan_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'SSD_US')
      ]
    },
    WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWeeklyPlan_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'SSD_US')
      ]
    },
    WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWtWeeklyInputPlan_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'SSD_US')
      ]
    },
    WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWtWeeklyPlan_HistoricalPlans: {
      rules: [
        ActivationRule.create(WILD, 'SSD_US')
      ]
    }
  });

  class Activation {
    static get featureSupport () {
      return FEATURE_SUPPORT;
    }

    static isAvailable (key) {
      if (!_.has(FEATURE_SUPPORT, key)) {
        // If the feature key is not in the support list then it is available for all environments and scopes
        return true;
      }
      const scope = Configuration.scopes.current();
      const environment = Configuration.environment.current();

      // localhost can have the key overridden to facilitate testing against all environments from localhost
      if (Configuration.environment.origin().isLocal()) {
        const value = Configuration.storage.session.get(key);
        if (_.isBoolean(value)) {
          return value;
        }
      }
      return _.some(FEATURE_SUPPORT[key].rules, (rule) => rule.isActivationRule && rule.isApplicable(environment, scope))
        || _.every(FEATURE_SUPPORT[key].rules, (rule) => !rule.isActivationRule && rule.isApplicable(environment, scope));
    }
  }
  Object.freeze(Activation);

  Configuration.defineProperty('activation', Activation);
})();
